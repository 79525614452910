import React, { useContext } from "react";
import NavBar from "../components/Navbar/NavBar";
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../context/LanguageContext';
import { InlineWidget } from 'react-calendly';
import WhatsAppIcon from '../components/WhatsappIcon/WhatsappIcon';

const DemoProduct = () => {
  const { language } = useContext(LanguageContext);
  const isEnglish = language === 'en';
  const number = '+972528059991';
  const message = isEnglish ? 'Hello, I would like to request a demo of Evolinq.' : 'שלום, אני רוצה לבקש הדגמה של אבולינק.';
  const calendlyUrl = 'https://calendly.com/noam-evolinq/30min';
  return (
    <>
      <Helmet>
        <title>Evolinq | Request a Demo</title>
        <meta name="description" content={isEnglish ? "Request a free demo of Evolinq's AI-driven supply chain management platform and see how our solutions can optimize your factory's procurement process." : "בקשו הדגמה חינמית של הפלטפורמה לניהול שרשרת האספקה המונעת בבינה מלאכותית של אבולינק, וראו כיצד הפתרונות שלנו יכולים לייעל את תהליך הרכש במפעל שלכם."} />
        <meta name="keywords" content="Evolinq, AI, Supply Chain, Demo, Management, Free Demo" />
        <link rel="canonical" href="https://www.evolinq.io/en/demo" />
        <meta property="og:title" content="Request a Demo - Evolinq" />
        <meta property="og:description" content={isEnglish ? "Discover how Evolinq's AI-driven platform can transform your supply chain management. Request a free demo today!" : "גלו כיצד הפלטפורמה המונעת בבינה מלאכותית של אבולינק יכולה לשנות את ניהול שרשרת האספקה שלכם. בקשו הדגמה חינמית עוד היום!"} />
        <meta property="og:url" content="https://www.evolinq.io/en/demo" />
        <meta property="og:image" content="https://www.evolinq.io/images/og-image-demo.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <NavBar />
      </div>
      <div id="demo" className="flex justify-center items-center flex-col mt-8 w-full bg-white py-12 lg:py-24">
        <div className="flex flex-col justify-between items-center mb-8">
          <h1 className="text-center text-2xl font-semibold mr-4">{isEnglish ? 'Start A Conversation With Us' : 'התחילו שיחה '}</h1>
          <a href={`https://wa.me/${number}?text=${message}`} target="_blank" rel="noopener noreferrer">
          <button className="text-white custom-bg-green inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl mt-4" smooth >
            <WhatsAppIcon number={number} />
            <h3 className="text-white ml-2">whatsapp</h3>
          </button>
          </a>
        </div>
        <div className="text-center text-gray-500 mb-4">{isEnglish ? 'OR' : 'או'}</div>
        <h1 className="text-center text-2xl font-semibold mr-4">{isEnglish ? 'Schedule A Demo' : 'קבעו פגישה'}</h1>
        <div className="container mx-auto px-4 lg:px-20" data-aos="zoom-in">
          <InlineWidget url={calendlyUrl} />
        </div>
      </div>
    </>
  );
};

export default DemoProduct;