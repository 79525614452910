import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../context/LanguageContext';

import blogPostImage1 from '../blogPosts/the-cornerstone-of-successful-manufacturing-companies/the-cornerstone-of-successful-manufacturing-companies_image.jpg';
import blogPostImage2 from '../blogPosts/coordinating-supplies-in-the-import-world/coordinating-supplies-in-the-import-world_image.jpg';
import blogPostImage3 from '../blogPosts/supply-management-in-times-of-war-and-epidemics/supply-management-in-times-of-war-and-epidemics_image.jpg';
import blogPostImage4 from '../blogPosts/direct-vs-indirect-procurement/direct-vs-indirect-procurement.png';

const blogPosts = [
    {
        id: 1,
        title: {
            en: 'The Cormerstone of Successful Manufacturing Companies',
            he: 'תיאום אספקה: אבן היסוד של חברות ייצור מצליחות'
        },
        description: {
            en: 'Discover how AI is transforming supply chains and what the future holds.',
            he: 'כאשר מדברים על ניהול עסקי, קל לחשוב על מוצר חדשני, שיווק חכם, או שירות לקוחות מעולה כגורמים המרכזיים להצלחת חברה. אבל יש גורם אחד שלעתים קרובות מתעלמים ממנו, והוא קריטי יותר מכל...'
        },
        imageUrl: blogPostImage1,
        uploadTime: {
            en: 'December 14, 2023',
            he: '14 בדצמבר, 2023'
        },
        link: '/blog/the-cornerstone-of-successful-manufacturing-companies'
    },
    {
        id: 2,
        title: {
            en: 'Coordinating Supplies in the Import World',
            he: 'תיאום אספקות בעולמות הייבוא: אתגרים, סכנות, ופתרונות לניהול חכם'
        },
        description: {
            en: 'Learn the best strategies for managing supplier relationships effectively.',
            he: 'בעולם הגלובלי של ימינו, עסקים שמתמודדים עם ייבוא מוצרים תלויים בתיאום מדויק של תהליך האספקות. מהות תיאום האספקות בעולמות הייבוא היא לשמור על זרימה חלקה של סחורות ושירותים בין מדינות...'
        },
        imageUrl: blogPostImage2,
        uploadTime: {
            en: 'February 22, 2024',
            he: '22 בפברואר, 2024'
        },
        link: '/blog/coordinating-supplies-in-the-import-world'
    },
    {
      id: 3,
      title: {
          en: 'Supply Management in Times of War and Epidemics',
          he: 'ניהול אספקות בזמני מלחמה ומגפות: אתגרים, היערכות ופתרונות מתקדמים'
      },
      description: {
          en: 'Explore the latest innovations in procurement and how they can benefit your business.',
          he: 'ניהול שרשראות אספקה בעידן המודרני הוא תהליך מורכב ורגיש גם בימים כתיקונם, אך כשמלחמות פורצות או מגפות מתפרצות, האתגרים הללו מגיעים לרמות חדשות של קושי. בזמנים כאלו, חברות נאלצות להתמודד עם קטיעת שרשראות אספקה, מחסור בחומרי גלם, עלויות לוגיסטיקה גבוהות, וניהול כוח אדם בתנאי חירום...'
      },
      imageUrl: blogPostImage3,
      uploadTime: {
          en: 'August 15, 2024',
          he: '15 באוגוסט, 2024'
      },
      link: '/blog/supply-management-in-times-of-war-and-epidemics'
    },
    {
      id: 4,
      title: {
          en: 'Direct vs Indirect Procurement',
          he: 'רכש ישיר מול רכש עקיף'
      },
      description: {
          en: 'Discover how AI is transforming both direct and indirect procurement processes, revolutionizing traditional supply chain management through intelligent automation and real-time optimization.',
          he: 'גלו כיצד בינה מלאכותית משנה את תהליכי הרכש הישיר והעקיף, מחוללת מהפכה בניהול המסורתי של שרשרת האספקה באמצעות אוטומציה חכמה ואופטימיזציה בזמן אמת.'
      },
      imageUrl: blogPostImage4,
      uploadTime: {
          en: 'November 10, 2024',
          he: '10 בנובמבר, 2024'
      },
      link: '/blog/direct-vs-indirect-procurement'
    }
];

const Blog = () => {
    const { language } = useContext(LanguageContext);
    const isEnglish = language === 'en';

    return (
      <div>
        <Helmet>
          <title>{isEnglish ? "Evolinq | Blog" : "אבולינק | בלוג"}</title>
          <meta
            name="description"
            content={isEnglish
              ? "Read the latest articles on supply chain trends and innovations."
              : "קראו את המאמרים האחרונים על טרנדים וחידושים בשרשרת האספקה."}
          />
          <meta
            name="keywords"
            content={isEnglish
              ? "Evolinq Blog, Supply Chain Articles, AI in Procurement"
              : "בלוג אבולינק, מאמרים על שרשרת אספקה, בינה מלאכותית ברכש"
            }
          />
          <link rel="canonical" href="https://www.evolinq.io/en/blog" />
        </Helmet>
         <NavBar />
         <div
        id="careers"
        className="py-12 bg-cover bg-center min-h-screen"
      >
        <section data-aos="zoom-in-down">
            <div style={{backgroundColor: "black", backgroundImage: "linear-gradient(black , #1565C0)", paddingTop: "70px", paddingBottom: "70px", marginTop: "50px", marginBottom: "50px"}}>
            <div className="my-4 py-4">
              <h1 className="my-2 text-center text-6xl text-white font-bold">
                {isEnglish ? "Blog" : "בלוג"}
              </h1>
              <h3 className="text-center text-3xl text-white mb-10 mt-10">
                {isEnglish
                  ? "Articles on the Latest Supply Chain Trends"
                  : "מאמרים על טרנדים בשרשרת האספקה"}
              </h3>
            </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-6">
                {blogPosts.map(post => (
                  <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                      <img src={post.imageUrl} alt={post.title[language]} className="w-full h-48 object-cover" />
                      <div className="p-4">
                          <h2 className="text-2xl font-semibold mb-2">{post.title[language]}</h2>
                          <p className="text-gray-600 mb-4">{post.description[language]}</p>
                          <p className="text-gray-500 text-sm mb-2">{post.uploadTime[language]}</p>
                          <Link to={`/${language}${post.link}`} className="text-blue-500 hover:underline">
                              {isEnglish ? 'Read More' : 'קרא עוד'}
                          </Link>
                      </div>
                  </div>
              ))}
            </div>
            </section>
            </div>
        </div>
    );
};

export default Blog;
